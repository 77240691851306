<template>
  <div class="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
    <!-- Navigation remains the same -->
    <nav class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex items-center">
            <span class="text-2xl font-bold text-indigo-600">REM Workers</span>
          </div>
          <div class="hidden sm:flex items-center space-x-8">
            <a href="#about" class="text-gray-700 hover:text-indigo-600">About Us</a>
            <a href="#services" class="text-gray-700 hover:text-indigo-600">Services</a>
            <a href="#contact" class="text-gray-700 hover:text-indigo-600">Contact</a>
            <!-- <button class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700">
              Get Started
            </button> -->
          </div>
        </div>
      </div>
    </nav>

    <main>
      <!-- Hero Section remains the same -->
      <section class="relative py-20 overflow-hidden">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="text-center">
            <h1 class="text-4xl sm:text-5xl font-bold text-gray-900 mb-6">
              Revolutionizing Remote Engineering & Management
            </h1>
            <p class="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              AI-driven assessments, certifications, and real-world experience for the next generation of professionals.
            </p>
            <div class="space-x-4">
              <button class="bg-indigo-600 text-white px-8 py-3 rounded-md hover:bg-indigo-700" @click="isDialogOpen = true">
                Join as a Candidate
              </button>
              <button class="bg-white text-indigo-600 px-8 py-3 rounded-md border border-indigo-600 hover:bg-indigo-50" @click="isHireDialogOpen = true">
                Hire Talent
              </button>
            </div>
          </div>
        </div>
      </section>

      <!-- Statistics Section -->
      <section class="py-16 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <!-- <div class="grid grid-cols-2 md:grid-cols-4 gap-8">
            <StatCard 
              v-for="stat in statistics" 
              :key="stat.label"
              :value="stat.value"
              :label="stat.label"
              :suffix="stat.suffix"
            />
          </div> -->
          <StatCard/>
        </div>
      </section>

      <!-- About Us Section -->
      <section id="about" class="py-16 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold text-center text-gray-900 mb-8">About Us</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h3 class="text-2xl font-semibold mb-4">Transforming Remote Work</h3>
              <p class="text-gray-600 mb-6">
                At REM Workers, we're revolutionizing how companies build and manage remote engineering teams. Our AI-driven platform combines cutting-edge technology with human expertise to ensure perfect matches between talent and opportunities.
              </p>
              <p class="text-gray-600">
                Founded by industry veterans, we understand the challenges of remote work and have built solutions that make remote team building and management seamless and efficient.
              </p>
            </div>
            <div class="grid grid-cols-2 gap-4">
              <div class="bg-white p-6 rounded-lg shadow-sm">
                <Users class="w-8 h-8 text-indigo-600 mb-3" />
                <h4 class="font-semibold mb-2">Global Network</h4>
                <p class="text-sm text-gray-600">Access talent from around the world</p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-sm">
                <Shield class="w-8 h-8 text-indigo-600 mb-3" />
                <h4 class="font-semibold mb-2">Verified Skills</h4>
                <p class="text-sm text-gray-600">Rigorous vetting process</p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-sm">
                <Zap class="w-8 h-8 text-indigo-600 mb-3" />
                <h4 class="font-semibold mb-2">Fast Matching</h4>
                <p class="text-sm text-gray-600">AI-powered talent matching</p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-sm">
                <HeartHandshake class="w-8 h-8 text-indigo-600 mb-3" />
                <h4 class="font-semibold mb-2">Long-term Success</h4>
                <p class="text-sm text-gray-600">Ongoing support and growth</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Services Section -->
      <section id="services" class="py-16 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold text-center text-gray-900 mb-12">Our Services</h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div class="bg-gray-50 rounded-lg p-8">
              <UserCheck class="w-12 h-12 text-indigo-600 mb-4" />
              <h3 class="text-xl font-semibold mb-4">Talent Assessment</h3>
              <ul class="space-y-3 text-gray-600">
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Technical skill evaluation
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Soft skills assessment
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Cultural fit analysis
                </li>
              </ul>
            </div>
            <div class="bg-gray-50 rounded-lg p-8">
              <Users2 class="w-12 h-12 text-indigo-600 mb-4" />
              <h3 class="text-xl font-semibold mb-4">Team Building</h3>
              <ul class="space-y-3 text-gray-600">
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Custom team assembly
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Skill gap analysis
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Team scaling support
                </li>
              </ul>
            </div>
            <div class="bg-gray-50 rounded-lg p-8">
              <BarChart class="w-12 h-12 text-indigo-600 mb-4" />
              <h3 class="text-xl font-semibold mb-4">Performance Tracking</h3>
              <ul class="space-y-3 text-gray-600">
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Real-time monitoring
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Progress reporting
                </li>
                <li class="flex items-center">
                  <Check class="w-5 h-5 text-green-500 mr-2" />
                  Performance optimization
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <!-- Features Section remains the same -->
      <section class="py-16 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold text-center text-gray-900 mb-12">
            Why Choose REM Workers?
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              v-for="feature in features"
              :key="feature.title"
              :icon="feature.icon"
              :title="feature.title"
              :description="feature.description"
            />
          </div>
        </div>
      </section>

      <!-- How It Works Section -->
      <section class="py-16 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold text-center text-gray-900 mb-12">
            How It Works
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
            <ProcessStep
              v-for="step in steps"
              :key="step.title"
              :icon="step.icon"
              :title="step.title"
              :description="step.description"
            />
          </div>
        </div>
      </section>

      <!-- Contact Section -->
      <section id="contact" class="py-12 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold text-center text-gray-900 mb-12">Contact Us</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h3 class="text-xl font-semibold mb-4">Get in Touch</h3>
              <p class="text-gray-600 mb-6">
                Have questions about our services? We're here to help you find the perfect solution for your needs.
              </p>
              <div class="space-y-4">
                <div class="flex items-center">
                  <MapPin class="w-10 h-10 text-indigo-600 mr-3" />
                  <span class="text-gray-600">Office Address: Office No. 303 Third Floor, 
                    Plot No.3, Anupam Plaza, LSC, Mayur Vihar, Phase - 1, New Delhi-110091</span>
                </div>
                <div class="flex items-center">
                  <Mail class="w-6 h-6 text-indigo-600 mr-3" />
                  <span class="text-gray-600">info@remworkers.com</span>
                </div>
                <div class="flex items-center">
                  <Phone class="w-6 h-6 text-indigo-600 mr-3" />
                  <span class="text-gray-600">+919632881279</span>
                </div>
                <div class="flex items-center">
                  <CircleUser class="w-6 h-6 text-indigo-600 mr-3" />
                  <span class="text-gray-600">HR Manager: <br> Srilakshmi Kothakapol</span>
                </div>
                <div class="flex items-center">
                  <User class="w-6 h-6 text-indigo-600 mr-3" />
                  <span class="text-gray-600">Company Directors: <br> Taracad Ravichandran, Radhika Ravichandran</span>
                </div>
              </div>
            </div>
            <div class="bg-white rounded-lg p-8 shadow-sm">
              <form @submit.prevent="submitMessage" class="space-y-6">
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                  <input v-model="message.name" required type="text" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input v-model="message.email" required type="email" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-1">Message</label>
                  <textarea v-model="message.message" required rows="4" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"></textarea>
                </div>
                <button type="submit" class="w-full bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <!-- CTA Section remains the same -->
      <!-- <section class="py-16 bg-indigo-600">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 class="text-3xl font-bold text-white mb-6">
            Ready to Elevate Your Career or Find Top Talent?
          </h2>
          <div class="space-x-4">
            <button class="bg-white text-indigo-600 px-8 py-3 rounded-md hover:bg-gray-100">
              Sign Up Now
            </button>
            <button class="border border-white text-white px-8 py-3 rounded-md hover:bg-indigo-700">
              Explore More
            </button>
          </div>
        </div>
      </section> -->
    </main>

    <div
      v-if="isDialogOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <!-- Dialog Box -->
      <div
        class="bg-white rounded-lg shadow-lg max-w-md w-full p-6 space-y-6 relative"
      >
        <!-- Close Button -->
        <h3 class="text-xl font-bold mt-0 mb-4">Join as a Candidate</h3>
        <button
          class="absolute top-0 right-4 bg-gray-100 hover:bg-gray-200 text-gray-600 rounded-full p-2 focus:outline-none"
          @click="isDialogOpen = false"
        >
        <X class="w-4 h-4" />
        </button>

        <!-- Form -->
        <form @submit.prevent="handleCandidateSubmit">
          <div class="space-y-4">
            <!-- Name Field -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                type="text"
                v-model="form.name"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>

            <!-- Email Field -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                v-model="form.email"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>

            <!-- Skills Field -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">
                Skills (comma-separated)
              </label>
              <input
                type="text"
                v-model="form.skills"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
          </div>

          <!-- Submit Button -->
          <button
            type="submit"
            class="w-full mt-6 bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700"
          >
            Submit Application
          </button>
        </form>
      </div>
    </div>

    <!-- Dialog -->
    <div 
      v-if="isHireDialogOpen" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white w-11/12 md:w-1/2 rounded-lg shadow-lg relative">
        <!-- Close Button -->
        <button 
          class="absolute top-2 right-2 bg-gray-100 hover:bg-gray-200 rounded-full p-2"
          @click="isHireDialogOpen = false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>

        <!-- Dialog Content -->
        <div class="p-6">
          <h3 class="text-lg font-bold mb-4">Hire Talent</h3>
          <p class="text-gray-700 mb-6">
            Provide the details of the professional you're looking for.
          </p>

          <!-- Input Fields -->
          <form @submit.prevent="submitHireForm">
            <!-- Name -->
            <div class="mb-4">
              <label for="name" class="block text-gray-700">Name</label>
              <input 
                type="text" 
                id="name" 
                v-model="hireForm.name"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <!-- Email -->
            <div class="mb-4">
              <label for="email" class="block text-gray-700">Email</label>
              <input 
                type="email" 
                id="email" 
                v-model="hireForm.email"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <!-- Preferred Technologies -->
            <div class="mb-4">
              <label for="technologies" class="block text-gray-700">Preferred Technologies</label>
              <input 
                type="text" 
                id="technologies" 
                v-model="hireForm.technologies"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <!-- Position -->
            <div class="mb-4">
              <label for="position" class="block text-gray-700">Position</label>
              <input 
                type="text" 
                id="position" 
                v-model="hireForm.position"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <!-- Experience -->
            <div class="mb-4">
              <label for="experience" class="block text-gray-700">Experience (Years)</label>
              <input 
                type="number" 
                id="experience" 
                v-model="hireForm.experience"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <!-- Submit Button -->
            <button 
              type="submit"
              class="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>

  <!-- Success Dialog Box -->
  <div
      v-if="joinCandidateSuccess"
      class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
    <div class="bg-white p-6 rounded-lg shadow-lg w-96">
      <h3 class="text-xl font-semibold text-green-600">Success</h3>
      <p class="mt-4 text-gray-800">Thank you your application has been submitted.</p>
      <div class="mt-6 flex justify-end">
        <button
          @click="joinCandidateSuccess = false"
          class="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
        >
          Close
        </button>
      </div>
    </div>
  </div>
  <!-- Failure Dialog Box -->
  <div
      v-if="joinCandidateFailure"
      class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
    <div class="bg-white p-6 rounded-lg shadow-lg w-96">
      <h3 class="text-xl font-semibold text-red-600">Failure</h3>
      <p class="mt-4 text-gray-800">Error while submitting. Please try again later.</p>
      <div class="mt-6 flex justify-end">
        <button
          @click="joinCandidateFailure = false"
          class="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
        >
          Close
        </button>
      </div>
    </div>
  </div>
  <!-- Policy Dialog -->
  <div 
      v-if="showPolicy" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white w-11/12 lg:w-1/2 rounded-lg shadow-lg relative ">
        <!-- Close Button -->
        <button 
          class="absolute top-2 right-2 bg-gray-100 hover:bg-gray-200 rounded-full p-2"
          @click="showPolicy = false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>

        <!-- Dialog Content -->
        <div class="p-6">
          <h3 class="text-lg font-bold mb-4">Privacy and Policies</h3>

          <!-- Scrollable Text Area -->
          <div 
            class="overflow-y-auto h-[600px] px-4 py-2 border rounded-lg bg-gray-100"
          >
          <div class="privacy-policy">
            <h2 class="text-2xl font-bold">REM Workers Pvt Ltd</h2>
            <br>
            <p>
              REM Workers Pvt Ltd is committed to protecting and respecting your privacy. 
              This Privacy Policy explains how we collect, use, disclose, and safeguard your 
              personal information when you use our services, including our website and other related 
              services (collectively, the "Services").
            </p>
            <p>
              By accessing or using our Services, you agree to the terms of this Privacy Policy. 
              If you do not agree with the terms of this policy, please do not use our Services.
            </p>

            <h3 class="font-bold mt-4">1. Information We Collect</h3>
            <p>We may collect the following types of personal information:</p>
            <ul class="list-disc ml-5">
              <li>
                <strong>Personal Identification Information:</strong> Name, email address, phone number, 
                company name, and other information you provide when you interact with us, including signing 
                up for our services or contacting us.
              </li>
              <li><strong>Payment Information:</strong> Credit card details or billing information for services purchased.</li>
              <li>
                <strong>Usage Data:</strong> Information about how you interact with our website and Services, 
                including IP address, browser type, device type, pages visited, and other usage data.
              </li>
              <li>
                <strong>Cookies and Tracking Technologies:</strong> We may use cookies and similar tracking 
                technologies to collect information about your activity on our website for analysis and personalized service.
              </li>
            </ul>

            <h3 class="font-bold mt-4">2. How We Use Your Information</h3>
            <p>We use the information we collect for the following purposes:</p>
            <ul class="list-disc ml-5">
              <li>To provide, maintain, and improve our Services.</li>
              <li>To communicate with you, including responding to inquiries, sending updates, and offering customer support.</li>
              <li>To process transactions and manage your account.</li>
              <li>To personalize your experience and tailor content and offers to your preferences.</li>
              <li>To send promotional or marketing materials (with your consent, where required).</li>
              <li>To analyze usage patterns and improve the functionality of our website.</li>
              <li>
                To comply with legal obligations or protect our rights, property, or safety, and those of our users.
              </li>
            </ul>

            <h3 class="font-bold mt-4">3. How We Protect Your Information</h3>
            <p>
              We implement reasonable security measures to protect the confidentiality and integrity of your 
              personal information. These measures include encryption, secure servers, and restricted access to authorized personnel.
            </p>
            <p>
              However, no security system is completely foolproof, and we cannot guarantee the absolute security of your information.
            </p>

            <h3 class="font-bold mt-4">4. Sharing Your Information</h3>
            <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:</p>
            <ul class="list-disc ml-5">
              <li>
                <strong>Service Providers:</strong> We may share your information with third-party service providers who 
                perform services on our behalf, such as payment processors, hosting providers, or email marketing platforms.
              </li>
              <li>
                <strong>Legal Compliance:</strong> We may disclose your information if required by law, in response to legal requests, 
                or to protect our rights and safety.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may 
                be transferred as part of the transaction.
              </li>
            </ul>

            <h3 class="font-bold mt-4">5. Your Rights and Choices</h3>
            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
            <ul class="list-disc ml-5">
              <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
              <li>
                <strong>Correction:</strong> You can request corrections or updates to inaccurate or incomplete personal information.
              </li>
              <li>
                <strong>Deletion:</strong> You can request that we delete your personal information, subject to certain legal exceptions.
              </li>
              <li>
                <strong>Opt-Out of Marketing Communications:</strong> You can unsubscribe from marketing communications at any time by clicking the unsubscribe link in emails or contacting us directly.
              </li>
              <li>
                <strong>Data Portability:</strong> You can request a copy of your personal information in a structured, commonly used, and machine-readable format.
              </li>
              <li><strong>Object to Processing:</strong> You can object to the processing of your personal information in certain circumstances.</li>
            </ul>
            <p>To exercise your rights, please contact us at [Your contact email or address].</p>

            <h3 class="font-bold mt-4">6. Cookies and Tracking Technologies</h3>
            <p>
              We use cookies and similar tracking technologies to collect and store information when you visit our website. Cookies help us improve your experience and provide features like keeping you logged in, remembering your preferences, and analyzing website traffic.
            </p>
            <p>
              You can control cookies through your browser settings, and you can choose to accept or reject them. However, disabling cookies may impact your ability to use certain features of our website.
            </p>

            <h3 class="font-bold mt-4">7. Third-Party Links</h3>
            <p>
              Our website may contain links to third-party websites or services that are not operated by us. This Privacy Policy only applies to information collected through our Services, and we are not responsible for the privacy practices of third-party websites. We encourage you to review the privacy policies of those sites before providing any personal information.
            </p>

            <h3 class="font-bold mt-4">8. Children's Privacy</h3>
            <p>
              Our Services are not directed to individuals under the age of 18, and we do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that we may have collected personal information from a child, please contact us immediately, and we will take appropriate steps to delete that information.
            </p>

            <h3 class="font-bold mt-4">9. Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date" at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
            </p>

            <h3 class="font-bold mt-4">10. Contact Us</h3>
            <p>If you have any questions, concerns, or requests related to this Privacy Policy or our privacy practices, please contact us at:</p>
            <br>
            <address>
              REM Workers Pvt Ltd<br>
              Email: info@remworkers.com<br>
              Phone: +919632881279<br>
            </address>
          </div>

          </div>

          <!-- Close Button -->
          <div class="mt-4 text-right">
            <button 
              class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              @click="showPolicy = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog -->
  <div 
      v-if="showTS" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white w-11/12 lg:w-1/2 rounded-lg shadow-lg relative ">
        <!-- Close Button -->
        <button 
          class="absolute top-2 right-2 bg-gray-100 hover:bg-gray-200 rounded-full p-2"
          @click="showTS = false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>

        <!-- Dialog Content -->
        <div class="p-6">
          <h2 class="text-lg font-bold mb-4">Terms and Conditions for REM Workers Pvt Ltd</h2>
          <div class="overflow-y-auto h-[600px] px-4 py-2 border rounded bg-gray-100">
            <p><strong>Effective Date:</strong></p>
            <p>
              These Terms and Conditions ("Terms") govern your use of the website and services ("Services") provided by REM Workers Pvt Ltd. By accessing or using our website and Services, you agree to comply with these Terms. If you do not agree to these Terms, please refrain from using our website or Services.
            </p>

            <h3 class="font-semibold mt-4">1. Acceptance of Terms</h3>
            <p>
              By accessing or using the Services provided by REM Workers Pvt Ltd, you agree to be bound by these Terms and our Privacy Policy. If you are using the Services on behalf of a company, you represent that you have the authority to bind that company to these Terms.
            </p>

            <h3 class="font-semibold mt-4">2. Services Provided</h3>
            <p>
              REM Workers Pvt Ltd offers [describe services, e.g., remote workforce solutions, staffing services, outsourcing solutions, etc.]. The Services may include but are not limited to:
            </p>
            <ul class="list-disc ml-5">
              <li>Provision of remote workers for various roles</li>
              <li>Staffing solutions and recruitment</li>
              <li>Training and onboarding services</li>
              <li>[List other specific services your company provides]</li>
            </ul>

            <h3 class="font-semibold mt-4">3. Eligibility</h3>
            <p>
              You must be at least 18 years old to use our Services. By using the Services, you represent that you are at least 18 years old and capable of entering into a legally binding contract.
            </p>

            <h3 class="font-semibold mt-4">4. Account Registration</h3>
            <p>
              To use certain features of the Services, you may be required to create an account. When registering, you agree to provide accurate, current, and complete information about yourself or your company. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
            </p>

            <h3 class="font-semibold mt-4">5. User Responsibilities</h3>
            <p>You agree to use the Services only for lawful purposes and in accordance with these Terms. You shall not:</p>
            <ul class="list-disc ml-5">
              <li>Violate any applicable law or regulation.</li>
              <li>Use the Services in any way that could damage, disable, or impair the functionality of our website or services.</li>
              <li>Upload or transmit harmful or offensive content, including malware, viruses, or unauthorized scripts.</li>
              <li>Engage in fraudulent or misleading activities.</li>
              <li>Access any part of the Services that you are not authorized to access.</li>
            </ul>

            <h3 class="font-semibold mt-4">6. Fees and Payments</h3>
            <p>
              <strong>Fees:</strong> Fees for services provided by REM Workers Pvt Ltd will be specified in individual contracts or proposals and are subject to change. All pricing is quoted in [currency], and any applicable taxes (e.g., GST, VAT) will be added as required.
            </p>
            <p>
              <strong>Payment Terms:</strong> Payments are due as specified in your agreement with REM Workers Pvt Ltd. Late payments may incur additional fees or interest at a rate specified in the agreement.
            </p>
            <p>
              <strong>Refund Policy:</strong> Unless otherwise specified in the agreement, payments for services are non-refundable.
            </p>

            <h3 class="font-semibold mt-4">7. Intellectual Property</h3>
            <p>
              All content, designs, trademarks, logos, and intellectual property associated with REM Workers Pvt Ltd and its Services are owned by the Company or its licensors. You may not use, reproduce, or distribute any of the Company’s intellectual property without prior written permission.
            </p>

            <h3 class="font-semibold mt-4">8. Confidentiality</h3>
            <p>
              You agree to keep confidential all proprietary and confidential information disclosed by REM Workers Pvt Ltd, including but not limited to business processes, client information, employee data, and any other confidential information related to the Services. This confidentiality obligation will survive the termination of these Terms.
            </p>

            <h3 class="font-semibold mt-4">9. Privacy</h3>
            <p>
              Your use of our Services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using the Services, you consent to the collection and use of your information as described in the Privacy Policy.
            </p>

            <h3 class="font-semibold mt-4">10. Term and Termination</h3>
            <p>
              <strong>Term:</strong> These Terms will remain in effect as long as you continue to use our Services.
            </p>
            <p>
              <strong>Termination by You:</strong> You may terminate your use of the Services at any time by discontinuing use of the website and services and notifying us.
            </p>
            <p>
              <strong>Termination by Us:</strong> We reserve the right to suspend or terminate your access to the Services if you breach any of these Terms or engage in illegal activities. Upon termination, your account may be deactivated, and any outstanding payments will become due immediately.
            </p>

            <h3 class="font-semibold mt-4">11. Liability and Disclaimer</h3>
            <p>
              <strong>Limitation of Liability:</strong> REM Workers Pvt Ltd and its affiliates, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or goodwill, arising out of or related to your use of the Services.
            </p>
            <p>
              <strong>Disclaimer:</strong> The Services are provided "as is" and "as available" without warranties of any kind, either express or implied. We do not guarantee that the Services will be error-free, secure, or available at all times.
            </p>

            <h3 class="font-semibold mt-4">12. Indemnification</h3>
            <p>
              You agree to indemnify, defend, and hold harmless REM Workers Pvt Ltd and its affiliates, officers, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Services, your breach of these Terms, or your violation of any third-party rights.
            </p>

            <h3 class="font-semibold mt-4">13. Force Majeure</h3>
            <p>
              REM Workers Pvt Ltd shall not be held responsible for any failure or delay in performance caused by circumstances beyond its reasonable control, including but not limited to acts of God, natural disasters, government actions, labor disputes, or internet outages.
            </p>

            <h3 class="font-semibold mt-4">14. Governing Law and Dispute Resolution</h3>
            <p>
              <strong>Governing Law:</strong> These Terms are governed by and construed in accordance with the laws of [your jurisdiction].
            </p>
            <p>
              <strong>Dispute Resolution:</strong> Any disputes arising from or related to these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of [arbitration body or governing law]. The decision of the arbitrator shall be final and binding on both parties.
            </p>

            <h3 class="font-semibold mt-4">15. Modifications to Terms</h3>
            <p>
              We reserve the right to update or modify these Terms at any time. Any changes will be effective immediately upon posting to our website. Your continued use of the Services constitutes acceptance of the revised Terms.
            </p>

            <h3 class="font-semibold mt-4">16. Third-Party Services</h3>
            <p>
              Our Services may include integrations with third-party services or links to third-party websites. We are not responsible for the content, functionality, or practices of such third-party services. Your use of third-party services is governed by their respective terms and conditions.
            </p>

            <h3 class="font-semibold mt-4">17. Severability</h3>
            <p>
              If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
            </p>

            <h3 class="font-semibold mt-4">18. Entire Agreement</h3>
            <p>
              These Terms, along with our Privacy Policy and any additional agreements or terms applicable to specific services, constitute the entire agreement between you and REM Workers Pvt Ltd regarding the use of our Services and supersede any prior agreements.
            </p>


            <h3 class="font-semibold mt-4">19. Contact Us</h3>
            <p>
              If you have any questions or concerns about these Terms and Conditions, please contact us at:
            </p>
            <p>
              <strong>REM Workers Pvt Ltd</strong><br>
              Email: info@remworkers.com<br>
              Phone: +919632881279<br>
            </p>
          </div>

          <!-- Close Button -->
          <div class="mt-4 text-right">
            <button 
              class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              @click="showTS = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer remains the same -->
    <footer class="bg-gray-900 text-white py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <h3 class="text-xl font-bold mb-4">REM Workers</h3>
            <p class="text-gray-400">Building the future of remote work.</p>
          </div>
          <div>
            <h4 class="font-semibold mb-4">Quick Links</h4>
            <ul class="space-y-2">
              <li><a href="/#about" class="text-gray-400 hover:text-white">About Us</a></li>
              <li><a href="/#services" class="text-gray-400 hover:text-white">Services</a></li>
              <li><a href="/#contact" class="text-gray-400 hover:text-white">Contact</a></li>
            </ul>
          </div>
          <div>
            <h4 class="font-semibold mb-4">Legal</h4>
            <ul class="space-y-2">
              <li><a class="text-gray-400 hover:text-white" @click="showPolicy = true">Privacy Policy</a></li>
              <li><a class="text-gray-400 hover:text-white" @click="showTS = true">Terms of Service</a></li>
            </ul>
          </div>
          <!-- <div>
            <h4 class="font-semibold mb-4">Follow Us</h4>
            <div class="flex space-x-4">
              <a href="#" class="text-gray-400 hover:text-white">
                <Twitter class="w-6 h-6" />
              </a>
              <a href="https://www.linkedin.com/in/rem-workers-pvt-ltd-6aa98933b/" target="_blank" class="text-gray-400 hover:text-white">
                <Linkedin class="w-6 h-6" />
              </a>
              <a href="#" class="text-gray-400 hover:text-white">
                <Github class="w-6 h-6" />
              </a>
            </div>
          </div> -->
        </div>
        <div class="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
          © 2024 REM Workers. All rights reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { User , Twitter, Linkedin, Github, Users, Shield, Zap, HeartHandshake, UserCheck, Users2, BarChart, Check, MapPin, Mail, Phone, X, CircleUser } from 'lucide-vue-next'
import FeatureCard from './components/FeatureCard.vue'
import ProcessStep from './components/ProcessStep.vue'
import StatCard from './components/StatCard.vue'
import { ref } from "vue";
import axios from 'axios';

export default {
  mounted() {
    document.title = 'REM Workers';
  },
  components: {
        FeatureCard,
        ProcessStep,
        StatCard,
        X, User, Twitter, Linkedin, Github, Users, CircleUser, Shield, Zap, HeartHandshake, UserCheck, Users2, BarChart, Check, MapPin, Mail, Phone
    },
  setup() {
    const joinCandidateSuccess = ref(false);
    const joinCandidateFailure = ref(false);
    const isHireDialogOpen = ref(false);
    const isDialogOpen = ref(false);
    const showPolicy = ref(false);
    const showTS = ref(false);
    // var policies = "";
    const form = ref({
      name: "",
      email: "",
      skills: "",
    });
    const hireForm= ref({
        name: '',
        email: '',
        technologies: '',
        position: '',
        experience: '',
    });
    const message = ref({
      message:'',
      name:'',
      email:''
    });

    const handleCandidateSubmit = () => {
      const formData = {
        name: form.value.name,
        email: form.value.email,
        skills: form.value.skills,
        is_active: true
      };

      axios.post('https://www.remworkers.com/backend/api/join-candidate-details/', formData)
      .then(response => {
          isDialogOpen.value = false;
          joinCandidateSuccess.value = true;
          form.name = '';
          form.email = '';
          form.skills = '';
        })
      .catch(error => {
          console.error('Error submitting application:', error);
          isDialogOpen.value = false;
          joinCandidateFailure.value = true
          form.name = '';
          form.email = '';
          form.skills = '';
      });
    };
    const submitHireForm = () => {
      const formData = {
        name: hireForm.value.name,
        email: hireForm.value.email,
        prefered_technologies: hireForm.value.technologies,
        position: hireForm.value.position,
        experience: hireForm.value.experience,
        is_active: true
      };

      axios.post('https://www.remworkers.com/backend/api/hire-talent-details/', formData)
      .then(response => {
          isHireDialogOpen.value = false;
          joinCandidateSuccess.value = true;
          hireForm.name = '';
          hireForm.email = '';
          hireForm.technologies = '';
          hireForm.position = '';
          hireForm.experience = '';
        })
      .catch(error => {
          console.error('Error submitting application:', error);
          isHireDialogOpen.value = false;
          joinCandidateFailure.value = true
          form.name = '';
          form.email = '';
          form.skills = '';
      });
    };
    const submitMessage = () => {
      const formData = {
        name: message.value.name,
        email: message.value.email,
        message: message.value.message,
        is_active: true
      };

      axios.post('https://www.remworkers.com/backend/api/inquiry-details/', formData)
      .then(response => {
          joinCandidateSuccess.value = true;
          message.name = '';
          message.email = '';
          message.message = '';
        })
      .catch(error => {
          console.error('Error submitting application:', error);
          joinCandidateFailure.value = true
          message.name = '';
          message.email = '';
          message.skills = '';
      });
    };
    const features = [
      {
        icon: 'Cpu',
        title: 'AI-Powered Assessments',
        description: 'Advanced algorithms evaluate technical and soft skills accurately.'
      },
      {
        icon: 'Briefcase',
        title: 'Practical Experience',
        description: 'Real-world projects and hands-on learning opportunities.'
      },
      {
        icon: 'CheckCircle',
        title: 'Certification & Verification',
        description: 'Validated credentials that employers trust.'
      },
      {
        icon: 'Rocket',
        title: 'Future-Ready Workforce',
        description: 'Stay ahead with cutting-edge skills and training.'
      }
    ]

    const steps = [
      {
        icon: 'Brain',
        title: 'AI-Driven Tests',
        description: 'Complete comprehensive skill evaluations.'
      },
      {
        icon: 'Code',
        title: 'Practical Work',
        description: 'Apply your skills to real projects.'
      },
      {
        icon: 'CheckCircle2',
        title: 'Experience Verification',
        description: 'Get your work validated by experts.'
      },
      {
        icon: 'MessageSquare',
        title: 'LLM Integration',
        description: 'Enhanced communication and feedback.'
      }
    ]

    const statistics = [
      {
        value: 5000,
        label: 'Successful Placements',
        suffix: '+'
      },
      {
        value: 95,
        label: 'Client Satisfaction',
        suffix: '%'
      },
      {
        value: 50,
        label: 'Countries Reached',
        suffix: '+'
      },
      {
        value: 1000,
        label: 'Active Companies',
        suffix: '+'
      }
    ]
    return {
      submitHireForm,
      handleCandidateSubmit,
      submitMessage,
      isDialogOpen,
      showPolicy,
      showTS,
      form,
      features,
      steps,
      statistics,
      isHireDialogOpen,
      hireForm,
      joinCandidateSuccess,
      joinCandidateFailure,
      message
    }
  },
};
</script>
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "text-center" };
var _hoisted_2 = { class: "flex flex-col items-center" };
var _hoisted_3 = { class: "inline-flex items-center justify-center w-16 h-16 rounded-full bg-indigo-600 text-white mb-4" };
var _hoisted_4 = { class: "text-xl font-semibold text-gray-900 mb-2" };
var _hoisted_5 = { class: "text-gray-600" };
var lucide_vue_next_1 = require("lucide-vue-next");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ProcessStep',
    props: {
        icon: {},
        title: {},
        description: {}
    },
    setup: function (__props) {
        var props = __props;
        var icon = (0, vue_3.computed)(function () {
            var icons = { Brain: lucide_vue_next_1.Brain, Code: lucide_vue_next_1.Code, CheckCircle2: lucide_vue_next_1.CheckCircle2, MessageSquare: lucide_vue_next_1.MessageSquare };
            return icons[props.icon];
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.resolveDynamicComponent)(icon.value), { class: "w-8 h-8" }))
                    ]),
                    _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("div", { class: "relative" }, [
                        (0, vue_2.createElementVNode)("div", { class: "hidden md:block absolute top-8 -right-1/2 w-full border-t-2 border-indigo-200" })
                    ], -1)),
                    (0, vue_2.createElementVNode)("h3", _hoisted_4, (0, vue_2.toDisplayString)(_ctx.title), 1),
                    (0, vue_2.createElementVNode)("p", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.description), 1)
                ])
            ]));
        };
    }
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow" };
var _hoisted_2 = { class: "text-xl font-semibold text-gray-900 mb-2" };
var _hoisted_3 = { class: "text-gray-600" };
var lucide_vue_next_1 = require("lucide-vue-next");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FeatureCard',
    props: {
        icon: {},
        title: {},
        description: {}
    },
    setup: function (__props) {
        var props = __props;
        var icon = (0, vue_3.computed)(function () {
            var icons = { Cpu: lucide_vue_next_1.Cpu, Briefcase: lucide_vue_next_1.Briefcase, CheckCircle: lucide_vue_next_1.CheckCircle, Rocket: lucide_vue_next_1.Rocket };
            return icons[props.icon];
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.resolveDynamicComponent)(icon.value), { class: "w-12 h-12 text-indigo-600 mb-4" })),
                (0, vue_2.createElementVNode)("h3", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.title), 1),
                (0, vue_2.createElementVNode)("p", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.description), 1)
            ]));
        };
    }
});

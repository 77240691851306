"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "px-4 bg-white" };
var _hoisted_2 = { class: "container mx-auto" };
var _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8" };
var _hoisted_4 = { class: "text-xl font-semibold text-gray-900 mb-4" };
var _hoisted_5 = { class: "space-y-2" };
var lucide_vue_next_1 = require("lucide-vue-next");
exports.default = (0, vue_1.defineComponent)({
    __name: 'StatCard',
    setup: function (__props) {
        var categories = [
            {
                icon: lucide_vue_next_1.Megaphone,
                title: 'Sales & Marketing',
                items: [
                    'Marketing Automation',
                    'CRM Integration',
                    'Analytics Tools',
                    'Email Marketing',
                    'Social Media Management'
                ]
            },
            {
                icon: lucide_vue_next_1.Search,
                title: 'SEO & Performance',
                items: [
                    'Technical SEO',
                    'Content Optimization',
                    'Performance Monitoring',
                    'Mobile Optimization',
                    'Search Console Integration'
                ]
            },
            {
                icon: lucide_vue_next_1.Database,
                title: 'Languages & Databases',
                items: [
                    'Python, Django',
                    'PostgreSQL, SQL, Oracle',
                    'Vuejs, JavaScript, React',
                    'Rest APIs, GraphQL',
                    'Redis, Memcache',
                    'WebSocket Integration'
                ]
            },
            {
                icon: lucide_vue_next_1.Server,
                title: 'Server Infrastructure',
                items: [
                    'Cloud Computing',
                    'Containerization',
                    'CI/CD Pipelines',
                    'Microservices',
                    'Security Protocols'
                ]
            }
        ];
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("section", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("h2", { class: "text-3xl md:text-4xl font-bold text-center text-gray-900 mb-12" }, " Our Technology Stack ", -1)),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(categories, function (category) {
                            return (0, vue_2.createElementVNode)("div", {
                                key: category.title,
                                class: "p-6 rounded-xl border border-gray-200 hover:border-primary-300 transition-colors"
                            }, [
                                ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.resolveDynamicComponent)(category.icon), { class: "h-10 w-10 text-indigo-600 mb-4" })),
                                (0, vue_2.createElementVNode)("h3", _hoisted_4, (0, vue_2.toDisplayString)(category.title), 1),
                                (0, vue_2.createElementVNode)("ul", _hoisted_5, [
                                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(category.items, function (item) {
                                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                                            key: item,
                                            class: "flex items-center text-gray-600"
                                        }, [
                                            (0, vue_2.createVNode)((0, vue_2.unref)(lucide_vue_next_1.Check), { class: "h-4 w-4 text-green-500 mr-2" }),
                                            (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(item), 1)
                                        ]));
                                    }), 128))
                                ])
                            ]);
                        }), 64))
                    ])
                ])
            ]));
        };
    }
});
